.how {
  @media (max-width: 767px) {
    padding: 40px 15px;
  }
}

.how__inner {
  width: 990px;
  margin: 80px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1023px) {
    flex-direction: column;
    max-width: 100%;
    margin-top: 50px;
  }
}

.how__lt {
  width: 370px;
  height: 423px;
  img {
    width: 100%;
  }
  @media (max-width: 1023px) {
    display: none;
  }
}

.how__rt {
  width: 510px;
  @media (max-width: 560px) {
    width: 100%;
  }
}

.tab-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 55px;
  @media (max-width: 1023px) {
    margin-bottom: 35px;
  }
}

.tab-item {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  background-image: linear-gradient(transparent calc(100% - 10px), #663199 10px);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 1s;
  cursor: pointer;
  padding-bottom: 10px;
  @media (max-width: 1023px) {
    font-size: 11px;
    line-height: 14px;
  }
  &.active, &:hover {
    color: #663199;
    background-size: 100% 100%;
  }
  &:nth-child(2) {
    margin-left: 50px;
  }
}

.tab-contents {
  margin-bottom: 50px;
  @media (max-width: 1023px) {
    margin-bottom: 35px;
  }
  @media (max-width: 475px) {
    margin-bottom: 90px;
  }
}
.tab-content {
  display: none;
  &:first-of-type {
    display: block;
  }
}
.tab-content__head {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media (max-width: 1023px) {
    margin-bottom: 30px;
  }
}
.tab-content__text {
  color: #666e79;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  height: 120px;
  @media (max-width: 767px) {
    height: 160px;
  }
}

.how-sample {
  display: flex;
  flex-direction: column;
}
.how-sample__head {
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 15px;
}
.how-sample__button {
  width: 370px;
  height: 56px;
  @media (max-width: 767px) {
    width: 100%;
  }
  a {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}