.prefooter {
  background-color: #2b2727;
  padding: 30px 0;

  &__menu {

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (min-width:768px) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    li {
      margin-bottom: 10px;

      @media (min-width:768px) {
        width: 50%;
      }

      @media (min-width:1024px) {
        width: 33.33%;
      }

      @media (min-width:1200px) {
        width: 25%;
      }
    }

    a {
      text-decoration: none;
      color: #ffffff;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.7px;

      @media (min-width:768px) {
        transition: all .2s;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}