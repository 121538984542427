.footer{
  padding: 56px 0 59px 0;
  background: @black;
  border-bottom: 1px solid @footerBd;
  position: relative;

  @media (max-width: 768px) {
    padding-bottom: 100px;
  }

  &__wrapp, &__info, &__descr{
    .flex();

    @media (max-width: 768px){
      flex-direction: column;
    }

  }

  &__left, &__menu, &__right{
    .flex(@direction: column);
    flex: 1;
  }

  &__left, &__right{
    @media (max-width: 768px){
      align-items: center;
    }

    @media (max-width: 425px){
      align-items: center;
    }
  }

  &__terms, &__copy, &__descr, &__links, &__phone, &__secure{
    .font(@family: @roboto, @size: 12px, @weight: 300, @col: @white);
    color: @white;
    opacity: .7;
  }

  &__terms{
    margin: 37px 0 31px 0;

    a{
      position: relative;
      color: @white;
      opacity: .5;
      margin-right: 15px;
      transition: opacity .4s;

      &:hover{
        opacity: 1;
      }

      &:first-child{
        &:before{
          content:'';
          position: absolute;
          top: 0;
          right: -10px;
          height: 13px;
          width: 1px;
          background: @white;
        }

      }

      &:last-child{
        margin: 0;
      }
    }

  }

  &__copy{
    .font(@family: @open, @size: 12px, @weight: 300, @col: @white);
    margin-top: 29px;

    @media (max-width: 768px) {
      position: absolute;
      bottom: 15px;
      text-align: center;
    }
  }

  &__menu{
    align-items: center;
  }

  &__links{
    .font(@size: 14px, @lh: 36px, @transform: uppercase, @col: @white);
    opacity: .7;
    transition: opacity .4s;

    &:hover{
      opacity: 1;
    }
  }

  &__info{
    @media (max-width: 768px){
      flex-direction: row;
      margin-bottom: 20px;
    }
    @media (max-width: 425px){
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  &__secure, &__phone{
    .flex(@ali: center, @spacing: center);
    padding-left: 0;
    height: 48px;
    border-radius: 4px;
    border: 1px solid @footerBd;
    background: @footerInfo;
  }

  &__secure{
    width: 160px;
    text-transform: uppercase;

    @media (max-width: 1024px){
      margin-right: 10px;
    }

    @media (max-width: 425px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  &__phone{
    width: 180px;
  }

  &__lock, &__num{
    .flex(@ali: center);
    width: 33px;
    height: 33px;
    margin-right: 10px;
    border-radius: 100%;
    background-color: @black;
  }

  &__txt{
    a, p{
      .font(@family: @roboto, @size: 14px, @col: @white);
    }
  }

  &__descr, &__payments{
    text-align: right;
  }

  &__descr{
    @media (max-width: 768px){
      text-align: center;
      margin-bottom: 20px;
    }
  }

}

.footer__copy {
  @media (max-width: 767px) {
    text-align: center;
    margin-bottom: 20px;
  }
}
