.flex(@direction: row, @spacing: space-around, @ali: null, @alc: null, @wrap: nowrap){
  display: flex;
  flex-direction: @direction;
  justify-content: @spacing;
  align-items: @ali;
  align-content: @alc;
  flex-wrap: @wrap;
}

.font(@family: Arial, @size: 16px, @weight: 400, @lh: 24px, @ls: null, @transform: normal, @decoration: none, @col: @black, @ta: null){
  font-family: @family;
  font-size: @size;
  font-weight: @weight;
  line-height: @lh;
  letter-spacing: @ls;
  text-transform: @transform;
  text-decoration: @decoration;
  text-align: @ta;
  color: @col;
}
