[data-crm-widget="form"] {
  [data-crm="loader"] {
    display: none;
  }
}

.order-preloader {
  padding-top: 30px;
  @media (max-width: 1023px) {
    padding-top: 84px;
  }
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  animation: blink 1s infinite alternate;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    width: 200px;
    animation: loading 2s infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.18) 10%, rgba(255, 255, 255, 0.8) 45%, rgba(255, 255, 255, 0.8) 55%, rgba(255, 255, 255, 0.18) 90%, rgba(255, 255, 255, 0) 100%);
    background-size: 200% 100%;
    z-index: 1000;
  }

  &__title {
    max-width: 900px;
    width: 100%;
    height: 24px;
    background: #fa0;
    border-radius: 8px;
    margin: 0 auto 34px auto;
    @media (max-width: 1023px) {
      max-width: 600px;
    }
  }

  &__info {
    width: 100%;
    max-width: 1180px;
    height: 160px;
    margin: 80px auto 0 auto;
    background: #fa0;
    border-radius: 10px;
    @media (max-width: 1023px) {
      max-width: 600px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }

  &__left {
    flex: 0 1 580px;
    @media (max-width: 1023px) {
      flex: 0 1 600px;
    }
    @media (max-width: 767px) {
      flex: 0 1 auto;
      width: 100%;
    }
  }

  &__right {
    margin-left: 20px;
    flex: 0 1 380px;
    height: 293px;
    width: 100%;
    background: #fa0;
    border-radius: 8px;
    @media (max-width: 1023px) {
      display: none;
    }
  }

  &__field {
    margin-bottom: 18px;
  }

  &__label {
    width: 100px;
    height: 12px;
    background: #fa0;
    border-radius: 8px;
    margin-bottom: 5px;

    &_lg {
      margin-top: 40px;
      margin-bottom: 15px;
      height: 14px;
      max-width: 480px;
    }
  }

  &__input {
    height: 36px;
    width: 100%;
    background: #fa0;
    border-right: 5px;

    &_md {
      max-width: 480px;
      margin-bottom: 5px;
    }
  }

  &__group {
    display: flex;
    justify-content: space-between;

    & > div {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    background: #fa0;
    border-radius: 5px;
    height: 36px;
    width: 100%;

    &_xs {
      width: 36px;
      height: 36px;
      flex: none;
    }

    &_lg {
      max-width: 180px;
      height: 180px;
    }

    &_md {
      max-width: 180px;
    }
  }

  &__pages {
    flex: 0 1 280px;

    &-select {
      display: flex;
      justify-content: space-between;

      & > div {
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__spacing {
    flex: 0 1 280px;
    margin-left: 20px;
  }

  &__textarea {
    max-width: 580px;
    width: 100%;
    height: 230px;
    background: #fa0;
    border-radius: 10px;
  }


}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes loading {
  to {
    left: 100%;
    transform: translateX(0);
  }
}
