.guarantees__wrapper{
  background: #fafafa;
}
.guarantees-items{
  display: flex;
  justify-content: space-between;
  width: 1140px;
  margin: 72px 0;
  @media (max-width: 1200px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 767px) {
    width: 210px;
    margin: 40px auto;
  }
}
.guarantees-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1200px) {
    margin-bottom: 25px;
  }
  @media (max-width: 767px) {
    width: 210px;
    margin-bottom: 0;
  }
}
.guarantees-item__i{
  height: 54px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.guarantees-item__title{
  width: 200px;
  color: #2b2e33;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
}
.guarantees-item__text{
  width: 180px;
  color: #666e79;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
}
.guarantees__bt{
  width: 260px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 767px) {
    width: 100%;
  }
  p {
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;
  }
  a {
    margin: 0;
  }
  .help-btn {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.guarantees-items {
  .slick-prev, .slick-next {
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    svg {
      fill: #2f2f2f;
    }
    &.slick-disabled {
      svg {
        fill: #d1d1d1;
      }
    }
  }
}