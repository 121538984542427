.header__login {
  #of-widgets-login-tooltip .of-widgets-open-tooltip {
    background: rgba(0,0,0,0);
    margin-right: 20px;
  }
}
[data-crm-widget="loginTooltip"], [data-crm-widget="loginTooltip"]>div, .c-login, .c-login__btn-open{
  width: 100% !important;
  height: 100% !important;
}
.c-login__btn-open{
  display: flex;
  justify-content: center;
  align-items: center;
}

.header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: background .5s;

  &.static {
    position: static;
  }

  &__sticky{
    background: @black;
  }

  &__wrapp, &__phone{
    .flex(@ali: center, @spacing: space-between);
  }

  &__wrapp{
    height: 80px;
  }

  &__logo {

    &--desktop {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    &--mobile {
      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  .c-login {

    &__icon {
      display: none;
    }

    &__text {
      font-size: 11px;
      color: #ffffff;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }

  &__phone{
    flex-shrink: 0;
    flex-direction: column;

    @media (max-width: 1023px){
      display: none;
    }
  }

  &__call, &__number{
    .font(@family: @open, @size: 11px, @weight: 700, @col: @yellow, @lh: 14px);
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__number{
    position: relative;
    margin-left: 22px;
    color: @white;
    opacity: .8;
    @media (max-width: 1400px) {
      font-weight: 400;
    }
    &:before{
      content:'';
      position: absolute;
      top: 1px;
      left: -13px;
      .sprite(@phone);
    }
  }

  &__close{
    display: none;
  }

  &__navigation{
    position: relative;
    margin-right: 0;
    @media (max-width: 1023px){
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      right: auto;
      height: 100vh;
      padding: 20px;
      .flex(@direction: column, @spacing: flex-start);
      background: rgba(0,0,0, .85);
      opacity: 1;
      z-index: 4;
      transition: all .8s;
      transform: translateY(-100%);
      margin-right: 0;
      &__open{
        right: auto;
        top: 0;
        transform: translateY(0);
      }
    }
    @media (max-width: 1023px){
      width: 100%;
    }
    .header__logo, .header__phone, .header__login{
      display: block;
      @media (min-width: 1023px){
        display: none;
      }
    }
    .header__close{
      @media (max-width: 1023px) {
        display: block;
        position: absolute;
        top: 29px;
        right: 20px;
        width: 18px;
        height: 18px;

        span {
          position: absolute;
          width: 18px;
          height: 2px;
          top: -1px;
          right: -3px;
          margin: 10px 0;
          background: @white;

          &:first-child {
            transform: rotate(45deg);
          }
          &:last-child {
            transform: rotate(-45deg);
          }
        }
      }
    }
    .header__phone{
      @media (max-width: 1023px){
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        border-top: 1px solid #3b3b3b;
        margin-left: 0;
        text-align: center;
        padding-top: 40px;
      }
    }
    .header__nav{
      @media (max-width: 1023px){
        .flex(@direction: column, @ali: flex-start);
        width: 290px;
        margin: 40px 0 0 20px;
      }

    }
    .header__links{
      @media (max-width: 1023px){
        position: relative;

        &:after{
          content:'';
          position: absolute;
          top: 17px;
          right: -13px;
          .sprite(@menuArrow);
        }
      }

    }
    .header__login{
      color: @white;
      margin: 50px 0 50px 20px;

      @media (max-width: 1023px) {
        margin: 20px 0 40px 20px;
        .c-login .c-login__btn-open {
          width: 91px;
          height: 40px;
          border-radius: 5px;
          border: 1px solid #ffffff;
          display: flex;
          background: transparent;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          box-shadow: none;
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
          line-height: 42px;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-family: "Open Sans", sans-serif;
          text-transform: uppercase;
          padding: 0;
        }
      }
    }
  }

  &__nav{
    width: 392px;
    .flex(@spacing: space-between, @ali: center);
  }

  &__links{
    position: relative;
    .font(@family: @roboto, @weight: 300, @size: 12px, @transform: uppercase, @col: @white);
    transition: color .5s;
    @media (max-width: 1023px) {
      font-family: "Open Sans";
      font-weight: 700;
    }
    &:before{
      content:'';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 0;
      height: 1px;
      background: @yellow;
      transition: width .5s;
    }

    &:hover&:before{
      width: 100%;
    }

    &:hover{
      color: @yellow;
    }

    @media (max-width: 1023px){
      line-height: 46px;
    }

  }

  &__group{
    .flex(@ali: center);
  }

  &__burger{
    display: none;
    width: 20px;
    height: 20px;
    @media (max-width: 1023px){
      display: block;
    }
    span{
      display: block;
      height: 2px;

      background: @white;
      transition: all .5s;

      &:nth-child(2){
        margin: 5px 0;
      }
    }
    &__open{
      position: relative;

      span{
        position: absolute;
        width: 20px;
        top: 8px;
        &:first-child{
          transform: rotate(45deg);
        }
        &:nth-child(2){
          opacity: 0;
        }
        &:last-child{
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.header__logo {
  svg, use, path {
    fill: #ffffff;

    &.orange {
      fill: #FBB12B;
    }
  }
}

.header_smush, .header_dark {
  box-shadow: 0px 2px 13px -2px rgba(0,0,0,0.75);
  .header__logo {
    svg, use, path {
      fill: #000000;

      &.orange {
        fill: #FBB12B;
      }
    }
  }
  .header__links, .header__number {
    color: #000000;
  }
  .header__burger {
    span {
      background: #000000;
    }
  }
  .header__navigation {
    .header__logo {
      svg, use, path {
        @media (max-width: 1023px) {
          fill: #ffffff;

          &.orange {
            fill: #FBB12B;
          }
        }
      }
    }
    .header__links, .header__number {
      @media (max-width: 1023px) {
        color: #ffffff;
      }
    }
  }
}