.seo-page-content {

  h1 {
    .font(@family: @open, @size: 38px, @transform: uppercase, @lh: 48px, @weight: 700, @col: @dark, @ta: left);
    margin-bottom: 25px;
    @media (max-width: 992px) {
      font-size: 32px;
      line-height: 40px;
    }
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  h2 {
    .font(@family: @open, @size: 24px, @transform: uppercase, @lh: 30px, @weight: 600, @col: @dark, @ta: left);
    margin: 25px 0;
    @media (max-width: 992px) {
      font-size: 32px;
      line-height: 40px;
    }
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  h3 {
    .font(@family: @open, @size: 18px, @transform: uppercase, @lh: 26px, @weight: 600, @col: @dark, @ta: left);
    margin: 20px 0;
    @media (max-width: 992px) {
      font-size: 32px;
      line-height: 40px;
    }
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 36px;
    }
  }
}