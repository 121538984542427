*{
  box-sizing: border-box;
}

.container{
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}

section{
  padding: 85px 0;
  @media (max-width: 767px) {
    padding: 40px 0;
  }
}

.simple-page, .content{
  max-width: 960px;
  margin: 0 auto;
  padding-top: 140px;
  padding-bottom: 100px;
  flex: 5;
  color: @black;
  p{
    margin-bottom: 10px;
    line-height: 24px;
  }
  h3{
    font-size: 24px;
    font-weight: 500;
    margin: 24px 0;
  }
  ul{
    padding-left: 20px;
    margin: 15px 0;
  }
  li{
    list-style-type: disc;
    line-height: 24px;
  }
}

.title{
  .font(@family: @open, @size: 38px, @transform: uppercase, @lh: 48px, @weight: 700, @col: @dark, @ta: center);
  margin-bottom: 14px;
  @media (max-width: 992px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 36px;
  }
}

.subtitle{
  .font(@family: @roboto, @size: 16px, @weight: 300, @col: @lightGray, @lh: 24px, @ta: center);
}

.scroll-button{
  width: 50px;
  height: 50px;
  background: @black;
}

.order-wrap{
  padding-top: 100px;

  .cw-preview-btn {
    cursor: pointer;
  }
  @media(min-width: 768px) {
    .cw-addvantages-list {
      padding: 0 35px;
    }
  }
  @media(min-width: 1024px) {
    .cw-lets-start {
      padding-left: 31px;
    }
  }

  @media(min-width: 1250px) {
    .cw-lets-start {
      padding-left: 0;
    }

    .cw-addvantages-list {
      padding: 0;
    }
  }
}

.clickfrog, [data-crm="loader"] {
  display:none !important;
}

@media (max-width: 425px){

  .title{
    .font(@family: Arial, @size: 24px, @lh: 36px, @weight: 600, @col: @title);
    margin-bottom: 27px;
  }

  .subtitle{
    text-align: center;
    .font(@family: Arial, @size: 14px, @lh: 24px, @weight: 400, @col: @subtitle);
    margin-bottom: 50px;
  }

  section{
    padding: 40px 0;
  }
}

.cr-cb-button-block {
  display: none !important;
}