.faq{

  .subtitle{
    margin-bottom: 70px;
  }

  &__wrapp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 1023px) {
      flex-direction: column;
    }
    .help-btn {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__items{
    position: relative;
    margin-bottom: 50px;
  }

  &__inner{
    overflow: hidden;
    display: none;
    padding-top: 31px;

    p {
      .font(@family: @roboto, @size: 16px, @weight: 300, @lh: 26px, @col: @faqTxt);
      margin-bottom: 20px;
    }

    h3 {
      .font(@family: @roboto, @size: 20px, @weight: 600, @lh: 28px, @col: @faqTxt);
      margin-bottom: 20px;
    }

    ul {
      list-style: inside;
      margin-bottom: 20px;

      li {
        .font(@family: @roboto, @size: 16px, @weight: 300, @lh: 26px, @col: @faqTxt);
      }
    }
  }

  .toggle {
    display: block;
    width: 80%;
    .font(@family: @open, @size: 16px, @weight: 700, @transform: uppercase);
    padding-bottom: 12px;
    transition: background .3s ease;

    &-wrapp{
      border-bottom: 2px solid @faqBd;
      cursor: pointer;

      &-open{
        border-bottom: 2px solid  @violet;
      }
    }

    &-active{
      &:after{
        border-bottom: 1px solid red;
      }
    }
  }

  &__img{
    margin-left: 100px;

    @media (max-width: 1023px){
      display: none;
    }
  }

  .btn-wrap{
    .flex(@spacing: flex-start);
    @media (max-width: 1023px) {
      justify-content: center;
    }
    .help{
      margin: 0;
    }
  }

}


.plus{
  position: absolute;
  top: 0;
  right: 1px;
  width: 14px;
  height: 14px;
}

.closed {
  .vertical {
    transition: all 1s ease-in-out;
    transform: rotate(-90deg);
  }
  .horizontal {
    transition: all 1s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.opened {
  opacity: 1;
  .vertical {
    transition: all 1s ease-in-out;
    transform: rotate(90deg);
    background: #6600fe;
  }
  .horizontal {
    transition: all 1s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
    background: #6600fe;
  }
}

.horizontal {
  position: absolute;
  background: #b7b7b7;
  width: 14px;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.vertical {
  position: absolute;
  background: #b7b7b7;
  width: 2px;
  height: 14px;
  left: 50%;
  transform: translateX(-50%);
}

.faq__container {
  width: 100%;
}