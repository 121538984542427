@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rg-VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #0a0a0a;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  font-family: 'Open Sans', sans-serif;
}
.ceiu_header.header_smush {
  transform: translateY(0);
  background: #ffffff;
}
* {
  box-sizing: border-box;
}
.container {
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}
section {
  padding: 85px 0;
}
@media (max-width: 767px) {
  section {
    padding: 40px 0;
  }
}
.simple-page,
.content {
  max-width: 960px;
  margin: 0 auto;
  padding-top: 140px;
  padding-bottom: 100px;
  flex: 5;
  color: #0a0a0a;
}
.simple-page p,
.content p {
  margin-bottom: 10px;
  line-height: 24px;
}
.simple-page h3,
.content h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 24px 0;
}
.simple-page ul,
.content ul {
  padding-left: 20px;
  margin: 15px 0;
}
.simple-page li,
.content li {
  list-style-type: disc;
  line-height: 24px;
}
.title {
  font-family: 'Open Sans', sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: #2b2e33;
  margin-bottom: 14px;
}
@media (max-width: 992px) {
  .title {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .title {
    font-size: 28px;
    line-height: 36px;
  }
}
.subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: null;
  text-transform: normal;
  text-decoration: none;
  text-align: center;
  color: #666e79;
}
.scroll-button {
  width: 50px;
  height: 50px;
  background: #0a0a0a;
}
.order-wrap {
  padding-top: 100px;
}
.order-wrap .cw-preview-btn {
  cursor: pointer;
}
@media (min-width: 768px) {
  .order-wrap .cw-addvantages-list {
    padding: 0 35px;
  }
}
@media (min-width: 1024px) {
  .order-wrap .cw-lets-start {
    padding-left: 31px;
  }
}
@media (min-width: 1250px) {
  .order-wrap .cw-lets-start {
    padding-left: 0;
  }
  .order-wrap .cw-addvantages-list {
    padding: 0;
  }
}
.clickfrog,
[data-crm="loader"] {
  display: none !important;
}
@media (max-width: 425px) {
  .title {
    font-family: Arial;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: null;
    text-transform: normal;
    text-decoration: none;
    text-align: null;
    color: #000;
    margin-bottom: 27px;
  }
  .subtitle {
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: null;
    text-transform: normal;
    text-decoration: none;
    text-align: null;
    color: #707070;
    margin-bottom: 50px;
  }
  section {
    padding: 40px 0;
  }
}
.cr-cb-button-block {
  display: none !important;
}
.btn-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
}
.help-btn,
.helpShadow,
.order,
.header__btn,
.header__login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: null;
  color: #fff;
  position: relative;
  width: 260px;
  height: 50px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  transition: background .2s, color .6s;
}
.help-btn.white-btn,
.helpShadow.white-btn,
.order.white-btn,
.header__btn.white-btn,
.header__login.white-btn {
  background: #ffffff;
}
.help-btn:hover,
.helpShadow:hover,
.order:hover,
.header__btn:hover,
.header__login:hover {
  text-decoration: none;
}
.header__btn,
.header__login {
  width: 120px;
  height: 44px;
  background: #ffb300;
  border: 1px solid transparent;
  color: #fff;
}
@media (max-width: 768px) {
  .header__btn,
  .header__login {
    width: 91px;
    height: 40px;
    font-size: 11px;
  }
}
.chat {
  background: #fff;
  color: #663199;
}
.help-btn,
.button-cont button {
  background: #663199;
}
.help-btn:before,
.button-cont button:before {
  content: '';
  width: 216px;
  height: 18px;
  box-shadow: 0 13px 20px 0 rgba(37, 20, 161, 0.4);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .help-btn:before,
  .button-cont button:before {
    width: 90%;
  }
}
.help-btn:hover,
.button-cont button:hover {
  background: #8d09f1;
}
.help-btn:hover:before,
.button-cont button:hover:before {
  box-shadow: rgba(141, 9, 241, 0.41);
}
.help-btn_header:before,
.button-cont button_header:before {
  display: none;
}
.helpShadow {
  position: relative;
  border-radius: 5px;
  background-color: #663199;
}
.helpShadow:after {
  content: '';
  position: absolute;
  width: 216px;
  height: 6px;
  box-shadow: 0 8px 19px -5px #2414a1;
  bottom: 0;
  border-radius: 100px;
  opacity: 0.4;
}
.helpShadow:hover {
  color: #000;
}
.order {
  color: #663199;
  background: transparent;
  border: 1px solid #663199;
}
.order:hover {
  background-color: #663199;
  color: #fff;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}
/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
[data-crm-widget="form"] [data-crm="loader"] {
  display: none;
}
.order-preloader {
  padding-top: 30px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  animation: blink 1s infinite alternate;
}
@media (max-width: 1023px) {
  .order-preloader {
    padding-top: 84px;
  }
}
.order-preloader:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  width: 200px;
  animation: loading 2s infinite;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.18) 10%, rgba(255, 255, 255, 0.8) 45%, rgba(255, 255, 255, 0.8) 55%, rgba(255, 255, 255, 0.18) 90%, rgba(255, 255, 255, 0) 100%);
  background-size: 200% 100%;
  z-index: 1000;
}
.order-preloader__title {
  max-width: 900px;
  width: 100%;
  height: 24px;
  background: #fa0;
  border-radius: 8px;
  margin: 0 auto 34px auto;
}
@media (max-width: 1023px) {
  .order-preloader__title {
    max-width: 600px;
  }
}
.order-preloader__info {
  width: 100%;
  max-width: 1180px;
  height: 160px;
  margin: 80px auto 0 auto;
  background: #fa0;
  border-radius: 10px;
}
@media (max-width: 1023px) {
  .order-preloader__info {
    max-width: 600px;
  }
}
.order-preloader__wrapper {
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .order-preloader__wrapper {
    padding: 0 15px;
  }
}
.order-preloader__left {
  flex: 0 1 580px;
}
@media (max-width: 1023px) {
  .order-preloader__left {
    flex: 0 1 600px;
  }
}
@media (max-width: 767px) {
  .order-preloader__left {
    flex: 0 1 auto;
    width: 100%;
  }
}
.order-preloader__right {
  margin-left: 20px;
  flex: 0 1 380px;
  height: 293px;
  width: 100%;
  background: #fa0;
  border-radius: 8px;
}
@media (max-width: 1023px) {
  .order-preloader__right {
    display: none;
  }
}
.order-preloader__field {
  margin-bottom: 18px;
}
.order-preloader__label {
  width: 100px;
  height: 12px;
  background: #fa0;
  border-radius: 8px;
  margin-bottom: 5px;
}
.order-preloader__label_lg {
  margin-top: 40px;
  margin-bottom: 15px;
  height: 14px;
  max-width: 480px;
}
.order-preloader__input {
  height: 36px;
  width: 100%;
  background: #fa0;
  border-right: 5px;
}
.order-preloader__input_md {
  max-width: 480px;
  margin-bottom: 5px;
}
.order-preloader__group {
  display: flex;
  justify-content: space-between;
}
.order-preloader__group > div {
  margin-right: 5px;
}
.order-preloader__group > div:last-child {
  margin-right: 0;
}
.order-preloader__button {
  background: #fa0;
  border-radius: 5px;
  height: 36px;
  width: 100%;
}
.order-preloader__button_xs {
  width: 36px;
  height: 36px;
  flex: none;
}
.order-preloader__button_lg {
  max-width: 180px;
  height: 180px;
}
.order-preloader__button_md {
  max-width: 180px;
}
.order-preloader__pages {
  flex: 0 1 280px;
}
.order-preloader__pages-select {
  display: flex;
  justify-content: space-between;
}
.order-preloader__pages-select > div {
  margin-right: 5px;
}
.order-preloader__pages-select > div:last-child {
  margin-right: 0;
}
.order-preloader__spacing {
  flex: 0 1 280px;
  margin-left: 20px;
}
.order-preloader__textarea {
  max-width: 580px;
  width: 100%;
  height: 230px;
  background: #fa0;
  border-radius: 10px;
}
@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
@keyframes loading {
  to {
    left: 100%;
    transform: translateX(0);
  }
}
.header__login #of-widgets-login-tooltip .of-widgets-open-tooltip {
  background: rgba(0, 0, 0, 0);
  margin-right: 20px;
}
[data-crm-widget="loginTooltip"],
[data-crm-widget="loginTooltip"] > div,
.c-login,
.c-login__btn-open {
  width: 100% !important;
  height: 100% !important;
}
.c-login__btn-open {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: background .5s;
}
.header.static {
  position: static;
}
.header__sticky {
  background: #0a0a0a;
}
.header__wrapp,
.header__phone {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
}
.header__wrapp {
  height: 80px;
}
.header__logo--desktop {
  display: none;
}
@media (min-width: 768px) {
  .header__logo--desktop {
    display: block;
  }
}
@media (min-width: 768px) {
  .header__logo--mobile {
    display: none;
  }
}
.header .c-login__icon {
  display: none;
}
.header .c-login__text {
  font-size: 11px;
  color: #ffffff;
}
@media (min-width: 768px) {
  .header .c-login__text {
    font-size: 14px;
  }
}
.header__phone {
  flex-shrink: 0;
  flex-direction: column;
}
@media (max-width: 1023px) {
  .header__phone {
    display: none;
  }
}
.header__call,
.header__number {
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: null;
  text-transform: normal;
  text-decoration: none;
  text-align: null;
  color: #ffb300;
}
@media (max-width: 767px) {
  .header__call,
  .header__number {
    font-size: 14px;
  }
}
.header__number {
  position: relative;
  margin-left: 22px;
  color: #fff;
  opacity: .8;
}
@media (max-width: 1400px) {
  .header__number {
    font-weight: 400;
  }
}
.header__number:before {
  content: '';
  position: absolute;
  top: 1px;
  left: -13px;
  background-position: -134px -78px;
  background-image: url('img/icons.png');
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 10px;
  height: 10px;
}
.header__close {
  display: none;
}
.header__navigation {
  position: relative;
  margin-right: 0;
}
@media (max-width: 1023px) {
  .header__navigation {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: auto;
    height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: null;
    align-content: null;
    flex-wrap: nowrap;
    background: rgba(0, 0, 0, 0.85);
    opacity: 1;
    z-index: 4;
    transition: all .8s;
    transform: translateY(-100%);
    margin-right: 0;
  }
  .header__navigation__open {
    right: auto;
    top: 0;
    transform: translateY(0);
  }
}
@media (max-width: 1023px) {
  .header__navigation {
    width: 100%;
  }
}
.header__navigation .header__logo,
.header__navigation .header__phone,
.header__navigation .header__login {
  display: block;
}
@media (min-width: 1023px) {
  .header__navigation .header__logo,
  .header__navigation .header__phone,
  .header__navigation .header__login {
    display: none;
  }
}
@media (max-width: 1023px) {
  .header__navigation .header__close {
    display: block;
    position: absolute;
    top: 29px;
    right: 20px;
    width: 18px;
    height: 18px;
  }
  .header__navigation .header__close span {
    position: absolute;
    width: 18px;
    height: 2px;
    top: -1px;
    right: -3px;
    margin: 10px 0;
    background: #fff;
  }
  .header__navigation .header__close span:first-child {
    transform: rotate(45deg);
  }
  .header__navigation .header__close span:last-child {
    transform: rotate(-45deg);
  }
}
@media (max-width: 1023px) {
  .header__navigation .header__phone {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-top: 1px solid #3b3b3b;
    margin-left: 0;
    text-align: center;
    padding-top: 40px;
  }
}
@media (max-width: 1023px) {
  .header__navigation .header__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    align-content: null;
    flex-wrap: nowrap;
    width: 290px;
    margin: 40px 0 0 20px;
  }
}
@media (max-width: 1023px) {
  .header__navigation .header__links {
    position: relative;
  }
  .header__navigation .header__links:after {
    content: '';
    position: absolute;
    top: 17px;
    right: -13px;
    background-position: -279px -156px;
    background-image: url('img/icons.png');
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    width: 7px;
    height: 11px;
  }
}
.header__navigation .header__login {
  color: #fff;
  margin: 50px 0 50px 20px;
}
@media (max-width: 1023px) {
  .header__navigation .header__login {
    margin: 20px 0 40px 20px;
  }
  .header__navigation .header__login .c-login .c-login__btn-open {
    width: 91px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    display: flex;
    background: transparent;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    padding: 0;
  }
}
.header__nav {
  width: 392px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
}
.header__links {
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: null;
  color: #fff;
  transition: color .5s;
}
@media (max-width: 1023px) {
  .header__links {
    font-family: "Open Sans";
    font-weight: 700;
  }
}
.header__links:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background: #ffb300;
  transition: width .5s;
}
.header__links:hover.header__links:before {
  width: 100%;
}
.header__links:hover {
  color: #ffb300;
}
@media (max-width: 1023px) {
  .header__links {
    line-height: 46px;
  }
}
.header__group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
}
.header__burger {
  display: none;
  width: 20px;
  height: 20px;
}
@media (max-width: 1023px) {
  .header__burger {
    display: block;
  }
}
.header__burger span {
  display: block;
  height: 2px;
  background: #fff;
  transition: all .5s;
}
.header__burger span:nth-child(2) {
  margin: 5px 0;
}
.header__burger__open {
  position: relative;
}
.header__burger__open span {
  position: absolute;
  width: 20px;
  top: 8px;
}
.header__burger__open span:first-child {
  transform: rotate(45deg);
}
.header__burger__open span:nth-child(2) {
  opacity: 0;
}
.header__burger__open span:last-child {
  transform: rotate(-45deg);
}
.header__logo svg,
.header__logo use,
.header__logo path {
  fill: #ffffff;
}
.header__logo svg.orange,
.header__logo use.orange,
.header__logo path.orange {
  fill: #FBB12B;
}
.header_smush,
.header_dark {
  box-shadow: 0px 2px 13px -2px rgba(0, 0, 0, 0.75);
}
.header_smush .header__logo svg,
.header_dark .header__logo svg,
.header_smush .header__logo use,
.header_dark .header__logo use,
.header_smush .header__logo path,
.header_dark .header__logo path {
  fill: #000000;
}
.header_smush .header__logo svg.orange,
.header_dark .header__logo svg.orange,
.header_smush .header__logo use.orange,
.header_dark .header__logo use.orange,
.header_smush .header__logo path.orange,
.header_dark .header__logo path.orange {
  fill: #FBB12B;
}
.header_smush .header__links,
.header_dark .header__links,
.header_smush .header__number,
.header_dark .header__number {
  color: #000000;
}
.header_smush .header__burger span,
.header_dark .header__burger span {
  background: #000000;
}
@media (max-width: 1023px) {
  .header_smush .header__navigation .header__logo svg,
  .header_dark .header__navigation .header__logo svg,
  .header_smush .header__navigation .header__logo use,
  .header_dark .header__navigation .header__logo use,
  .header_smush .header__navigation .header__logo path,
  .header_dark .header__navigation .header__logo path {
    fill: #ffffff;
  }
  .header_smush .header__navigation .header__logo svg.orange,
  .header_dark .header__navigation .header__logo svg.orange,
  .header_smush .header__navigation .header__logo use.orange,
  .header_dark .header__navigation .header__logo use.orange,
  .header_smush .header__navigation .header__logo path.orange,
  .header_dark .header__navigation .header__logo path.orange {
    fill: #FBB12B;
  }
}
@media (max-width: 1023px) {
  .header_smush .header__navigation .header__links,
  .header_dark .header__navigation .header__links,
  .header_smush .header__navigation .header__number,
  .header_dark .header__navigation .header__number {
    color: #ffffff;
  }
}
.top-section {
  padding: 0;
  background-image: url(img/top-section-bg.jpg);
  background-repeat: no-repeat;
  background-position: 0 0;
}
.top-section__btn-text {
  margin-top: 9px;
  font-size: 12px;
  color: white;
  font-family: 'Roboto', sans-serif;
}
.top-section__btn-text b {
  font-weight: bold;
}
.top-section__wrapp {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
  height: 800px;
}
@media (max-width: 767px) {
  .top-section__wrapp {
    height: auto;
    padding-top: 110px;
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .top-section__wrapp .help-btn {
    width: 100%;
  }
}
.top-section__about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: null;
  flex-wrap: nowrap;
  width: 280px;
  height: 100%;
  padding-left: 40px;
  margin-left: -15px;
  background: rgba(0, 0, 0, 0.44);
  z-index: 1;
}
@media (max-width: 1024px) {
  .top-section__about {
    width: 260px;
  }
}
@media (max-width: 1023px) {
  .top-section__about {
    display: none;
  }
}
.top-section__txt {
  flex: 2;
  text-align: center;
}
.top-section__title {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: null;
  color: #fff;
}
.top-section__items {
  margin-bottom: 60px;
}
.top-section__items p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: null;
  text-transform: normal;
  text-decoration: none;
  text-align: null;
  color: #8a8988;
}
.top-section .main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: null;
  color: #fff;
}
@media (max-width: 768px) {
  .top-section .main-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: null;
    text-transform: uppercase;
    text-decoration: none;
    text-align: null;
    color: #fff;
  }
}
.top-section .br {
  display: block;
}
@media (max-width: 425px) {
  .top-section .br {
    display: inline-block;
  }
}
.top-section .main-subtitle {
  margin: 45px auto 45px auto;
  max-width: 630px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: null;
  color: #fff;
}
@media (max-width: 768px) {
  .top-section .main-subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: null;
    text-transform: uppercase;
    text-decoration: none;
    text-align: null;
    color: #fff;
  }
}
.top-section .help {
  width: 260px;
  height: 56px;
}
@media (max-width: 425px) {
  .top-section .help {
    width: 100%;
  }
}
.chatbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 210px;
  cursor: pointer;
}
.chatbar__label {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 767px) {
  .how {
    padding: 40px 15px;
  }
}
.how__inner {
  width: 990px;
  margin: 80px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1023px) {
  .how__inner {
    flex-direction: column;
    max-width: 100%;
    margin-top: 50px;
  }
}
.how__lt {
  width: 370px;
  height: 423px;
}
.how__lt img {
  width: 100%;
}
@media (max-width: 1023px) {
  .how__lt {
    display: none;
  }
}
.how__rt {
  width: 510px;
}
@media (max-width: 560px) {
  .how__rt {
    width: 100%;
  }
}
.tab-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 55px;
}
@media (max-width: 1023px) {
  .tab-list {
    margin-bottom: 35px;
  }
}
.tab-item {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  background-image: linear-gradient(transparent calc(90%), #663199 10px);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 1s;
  cursor: pointer;
  padding-bottom: 10px;
}
@media (max-width: 1023px) {
  .tab-item {
    font-size: 11px;
    line-height: 14px;
  }
}
.tab-item.active,
.tab-item:hover {
  color: #663199;
  background-size: 100% 100%;
}
.tab-item:nth-child(2) {
  margin-left: 50px;
}
.tab-contents {
  margin-bottom: 50px;
}
@media (max-width: 1023px) {
  .tab-contents {
    margin-bottom: 35px;
  }
}
@media (max-width: 475px) {
  .tab-contents {
    margin-bottom: 90px;
  }
}
.tab-content {
  display: none;
}
.tab-content:first-of-type {
  display: block;
}
.tab-content__head {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 40px;
}
@media (max-width: 1023px) {
  .tab-content__head {
    margin-bottom: 30px;
  }
}
.tab-content__text {
  color: #666e79;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  height: 120px;
}
@media (max-width: 767px) {
  .tab-content__text {
    height: 160px;
  }
}
.how-sample {
  display: flex;
  flex-direction: column;
}
.how-sample__head {
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 15px;
}
.how-sample__button {
  width: 370px;
  height: 56px;
}
@media (max-width: 767px) {
  .how-sample__button {
    width: 100%;
  }
}
.how-sample__button a {
  width: 100%;
  height: 100%;
  margin: 0;
}
.hiw {
  padding: 40px 0 0 0;
}
@media (max-width: 767px) {
  .hiw {
    padding: 40px 0 20px 0;
  }
}
@media (max-width: 425px) {
  .hiw .container {
    padding: 0;
  }
}
.hiw__wrapp {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: null;
  align-content: null;
  flex-wrap: nowrap;
}
@media (max-width: 768px) {
  .hiw__wrapp {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: null;
    align-content: null;
    flex-wrap: nowrap;
  }
}
@media (max-width: 767px) {
  .hiw__wrapp .btn-wrapp {
    display: none;
  }
}
.hiw__left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
  margin-top: 140px;
}
@media (max-width: 425px) {
  .hiw__left .btn-wrapp {
    display: none;
  }
}
@media (max-width: 768px) {
  .hiw__left {
    margin-top: 0;
  }
}
.hiw__right {
  width: 640px;
}
@media (max-width: 767px) {
  .hiw__right {
    width: 100%;
    margin: 0;
  }
}
.hiw__right .btn-wrapp {
  display: none;
}
@media (max-width: 425px) {
  .hiw__right .btn-wrapp {
    display: block;
    padding: 0 15px;
  }
}
@media (max-width: 425px) {
  .hiw__right .order {
    width: 100%;
    margin: 0;
  }
}
.hiw__txt {
  margin-bottom: 50px;
}
@media (max-width: 425px) {
  .hiw__txt {
    margin-bottom: 0;
  }
}
.hiw__title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: null;
  color: #1a1a1a;
  margin-bottom: 5px;
  z-index: 1;
}
.hiw__descr {
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: null;
  text-transform: normal;
  text-decoration: none;
  text-align: null;
  color: #484848;
}
.hiw__descr:before {
  position: absolute;
  font-size: 70px;
  font-weight: bold;
  color: #e9e9e9;
  top: -35px;
}
.hiw__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: null;
  align-content: null;
  flex-wrap: nowrap;
  position: relative;
  height: 140px;
  padding-left: 99px;
}
@media (max-width: 767px) {
  .hiw__items {
    padding-left: 15px;
    margin-bottom: 15px;
  }
  .hiw__items .hiw__title {
    color: #fff;
  }
  .hiw__items .hiw__descr {
    color: #f5f5f5;
  }
  .hiw__items .hiw__descr:before {
    color: #663199;
  }
}
.hiw__items:hover .hiw__descr {
  color: #f5f5f5;
}
.hiw__items:hover .hiw__descr:before {
  color: #663199;
}
.hiw__items:hover .hiw__title {
  color: #fff;
}
.hiw__items:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
@media (max-width: 767px) {
  .hiw__items-1 {
    background-image: url(img/hiw-1.jpg);
    opacity: 1;
  }
}
.hiw__items-1:hover.hiw__items-1:before {
  background-image: url(img/hiw-1.jpg);
  opacity: 1;
}
@media (max-width: 767px) {
  .hiw__items-1:hover.hiw__items-1:before {
    background-image: none;
    opacity: 0;
  }
}
.hiw__items-1 .hiw__descr:before {
  content: '1';
}
@media (max-width: 767px) {
  .hiw__items-2 {
    background-image: url(img/hiw-2.jpg);
    opacity: 1;
  }
}
.hiw__items-2:hover.hiw__items-2:before {
  background-image: url(img/hiw-2.jpg);
  opacity: 1;
}
@media (max-width: 767px) {
  .hiw__items-2:hover.hiw__items-2:before {
    background-image: none;
    opacity: 0;
  }
}
.hiw__items-2 .hiw__descr:before {
  content: '2';
}
@media (max-width: 767px) {
  .hiw__items-3 {
    background-image: url(img/hiw-3.jpg);
    opacity: 1;
  }
}
.hiw__items-3:hover.hiw__items-3:before {
  background-image: url(img/hiw-3.jpg);
  opacity: 1;
}
@media (max-width: 767px) {
  .hiw__items-3:hover.hiw__items-3:before {
    background-image: none;
    opacity: 0;
  }
}
.hiw__items-3 .hiw__descr:before {
  content: '3';
}
@media (max-width: 767px) {
  .hiw__items-4 {
    background-image: url(img/hiw-4.jpg);
    opacity: 1;
  }
}
.hiw__items-4:hover.hiw__items-4:before {
  background-image: url(img/hiw-4.jpg);
  opacity: 1;
}
@media (max-width: 767px) {
  .hiw__items-4:hover.hiw__items-4:before {
    background-image: none;
    opacity: 0;
  }
}
.hiw__items-4 .hiw__descr:before {
  content: '4';
}
@media (max-width: 425px) {
  .hiw__items-4 {
    margin-bottom: 20px;
  }
}
.faq .subtitle {
  margin-bottom: 70px;
}
.faq__wrapp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .faq__wrapp {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .faq__wrapp .help-btn {
    width: 100%;
  }
}
.faq__items {
  position: relative;
  margin-bottom: 50px;
}
.faq__inner {
  overflow: hidden;
  display: none;
  padding-top: 31px;
}
.faq__inner p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: null;
  text-transform: normal;
  text-decoration: none;
  text-align: null;
  color: #767d87;
  margin-bottom: 20px;
}
.faq__inner h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: null;
  text-transform: normal;
  text-decoration: none;
  text-align: null;
  color: #767d87;
  margin-bottom: 20px;
}
.faq__inner ul {
  list-style: inside;
  margin-bottom: 20px;
}
.faq__inner ul li {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: null;
  text-transform: normal;
  text-decoration: none;
  text-align: null;
  color: #767d87;
}
.faq .toggle {
  display: block;
  width: 80%;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: null;
  color: #0a0a0a;
  padding-bottom: 12px;
  transition: background .3s ease;
}
.faq .toggle-wrapp {
  border-bottom: 2px solid #979797;
  cursor: pointer;
}
.faq .toggle-wrapp-open {
  border-bottom: 2px solid #663199;
}
.faq .toggle-active:after {
  border-bottom: 1px solid red;
}
.faq__img {
  margin-left: 100px;
}
@media (max-width: 1023px) {
  .faq__img {
    display: none;
  }
}
.faq .btn-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: null;
  align-content: null;
  flex-wrap: nowrap;
}
@media (max-width: 1023px) {
  .faq .btn-wrap {
    justify-content: center;
  }
}
.faq .btn-wrap .help {
  margin: 0;
}
.plus {
  position: absolute;
  top: 0;
  right: 1px;
  width: 14px;
  height: 14px;
}
.closed .vertical {
  transition: all 1s ease-in-out;
  transform: rotate(-90deg);
}
.closed .horizontal {
  transition: all 1s ease-in-out;
  transform: rotate(-90deg);
  opacity: 1;
}
.opened {
  opacity: 1;
}
.opened .vertical {
  transition: all 1s ease-in-out;
  transform: rotate(90deg);
  background: #6600fe;
}
.opened .horizontal {
  transition: all 1s ease-in-out;
  transform: rotate(90deg);
  opacity: 0;
  background: #6600fe;
}
.horizontal {
  position: absolute;
  background: #b7b7b7;
  width: 14px;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}
.vertical {
  position: absolute;
  background: #b7b7b7;
  width: 2px;
  height: 14px;
  left: 50%;
  transform: translateX(-50%);
}
.faq__container {
  width: 100%;
}
.help {
  background: url("img/help-gb.jpg") center 0 no-repeat;
  background-size: cover;
  padding: 50px 0;
}
.help__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1150px;
}
@media (max-width: 1200px) {
  .help__inner {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}
.help__lt {
  width: 600px;
}
@media (max-width: 1200px) {
  .help__lt {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .help__lt {
    width: 100%;
    width: 260px;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .help__lt {
    width: 100%;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .help__lt .white-btn {
    width: 100%;
    margin: 0;
  }
}
.help__rt {
  width: 260px;
}
@media (max-width: 767px) {
  .help__rt {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .help__rt .white-btn {
    width: 100%;
    margin: 0;
  }
}
.help__title {
  color: #ffffff;
  font-size: 44px;
  font-weight: 700;
  line-height: 57px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .help__title {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (max-width: 768px) {
  .help__title {
    font-size: 32px;
    line-height: 40px;
    padding: 0 20px;
  }
}
.help__subtitle {
  font-family: 'Roboto';
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .help__subtitle {
    margin-bottom: 30px;
  }
}
@media (max-width: 1024px) {
  .help__subtitle {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 768px) {
  .help__subtitle {
    font-size: 16px;
    line-height: 24px;
  }
}
.guarantees__wrapper {
  background: #fafafa;
}
.guarantees-items {
  display: flex;
  justify-content: space-between;
  width: 1140px;
  margin: 72px 0;
}
@media (max-width: 1200px) {
  .guarantees-items {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .guarantees-items {
    width: 210px;
    margin: 40px auto;
  }
}
.guarantees-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1200px) {
  .guarantees-item {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .guarantees-item {
    width: 210px;
    margin-bottom: 0;
  }
}
.guarantees-item__i {
  height: 54px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.guarantees-item__title {
  width: 200px;
  color: #2b2e33;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 767px) {
  .guarantees-item__title {
    margin: 0 auto;
  }
}
.guarantees-item__text {
  width: 180px;
  color: #666e79;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
}
@media (max-width: 767px) {
  .guarantees-item__text {
    margin: 0 auto;
  }
}
.guarantees__bt {
  width: 260px;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 767px) {
  .guarantees__bt {
    width: 100%;
  }
}
.guarantees__bt p {
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 15px;
}
.guarantees__bt a {
  margin: 0;
}
@media (max-width: 767px) {
  .guarantees__bt .help-btn {
    width: 100%;
  }
}
.guarantees-items .slick-prev button,
.guarantees-items .slick-next button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.guarantees-items .slick-prev svg,
.guarantees-items .slick-next svg {
  fill: #2f2f2f;
}
.guarantees-items .slick-prev.slick-disabled svg,
.guarantees-items .slick-next.slick-disabled svg {
  fill: #d1d1d1;
}
.page-404__container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
}
.page-404__text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
}
.page-404__text h2 {
  font-size: 70px;
}
.prefooter {
  background-color: #2b2727;
  padding: 30px 0;
}
.prefooter__menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .prefooter__menu ul {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.prefooter__menu li {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .prefooter__menu li {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .prefooter__menu li {
    width: 33.33%;
  }
}
@media (min-width: 1200px) {
  .prefooter__menu li {
    width: 25%;
  }
}
.prefooter__menu a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.7px;
}
@media (min-width: 768px) {
  .prefooter__menu a {
    transition: all .2s;
  }
  .prefooter__menu a:hover {
    color: #ffffff;
  }
}
.footer {
  padding: 56px 0 59px 0;
  background: #0a0a0a;
  border-bottom: 1px solid #323232;
  position: relative;
}
@media (max-width: 768px) {
  .footer {
    padding-bottom: 100px;
  }
}
.footer__wrapp,
.footer__info,
.footer__descr {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: null;
  align-content: null;
  flex-wrap: nowrap;
}
@media (max-width: 768px) {
  .footer__wrapp,
  .footer__info,
  .footer__descr {
    flex-direction: column;
  }
}
.footer__left,
.footer__menu,
.footer__right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: null;
  align-content: null;
  flex-wrap: nowrap;
  flex: 1;
}
@media (max-width: 768px) {
  .footer__left,
  .footer__right {
    align-items: center;
  }
}
@media (max-width: 425px) {
  .footer__left,
  .footer__right {
    align-items: center;
  }
}
.footer__terms,
.footer__copy,
.footer__descr,
.footer__links,
.footer__phone,
.footer__secure {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: null;
  text-transform: normal;
  text-decoration: none;
  text-align: null;
  color: #fff;
  opacity: .7;
}
.footer__terms {
  margin: 37px 0 31px 0;
}
.footer__terms a {
  position: relative;
  color: #fff;
  opacity: .5;
  margin-right: 15px;
  transition: opacity .4s;
}
.footer__terms a:hover {
  opacity: 1;
}
.footer__terms a:first-child:before {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  height: 13px;
  width: 1px;
  background: #fff;
}
.footer__terms a:last-child {
  margin: 0;
}
.footer__copy {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: null;
  text-transform: normal;
  text-decoration: none;
  text-align: null;
  color: #fff;
  margin-top: 29px;
}
@media (max-width: 768px) {
  .footer__copy {
    position: absolute;
    bottom: 15px;
    text-align: center;
  }
}
.footer__menu {
  align-items: center;
}
.footer__links {
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: null;
  color: #fff;
  opacity: .7;
  transition: opacity .4s;
}
.footer__links:hover {
  opacity: 1;
}
@media (max-width: 768px) {
  .footer__info {
    flex-direction: row;
    margin-bottom: 20px;
  }
}
@media (max-width: 425px) {
  .footer__info {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
}
.footer__secure,
.footer__phone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
  padding-left: 0;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #323232;
  background: #1b1b1b;
}
.footer__secure {
  width: 160px;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .footer__secure {
    margin-right: 10px;
  }
}
@media (max-width: 425px) {
  .footer__secure {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.footer__phone {
  width: 180px;
}
.footer__lock,
.footer__num {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: null;
  flex-wrap: nowrap;
  width: 33px;
  height: 33px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: #0a0a0a;
}
.footer__txt a,
.footer__txt p {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: null;
  text-transform: normal;
  text-decoration: none;
  text-align: null;
  color: #fff;
}
.footer__descr,
.footer__payments {
  text-align: right;
}
@media (max-width: 768px) {
  .footer__descr {
    text-align: center;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer__copy {
    text-align: center;
    margin-bottom: 20px;
  }
}
.seo-page-content h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
  color: #2b2e33;
  margin-bottom: 25px;
}
@media (max-width: 992px) {
  .seo-page-content h1 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .seo-page-content h1 {
    font-size: 28px;
    line-height: 36px;
  }
}
.seo-page-content h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
  color: #2b2e33;
  margin: 25px 0;
}
@media (max-width: 992px) {
  .seo-page-content h2 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .seo-page-content h2 {
    font-size: 28px;
    line-height: 36px;
  }
}
.seo-page-content h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: null;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
  color: #2b2e33;
  margin: 20px 0;
}
@media (max-width: 992px) {
  .seo-page-content h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .seo-page-content h3 {
    font-size: 28px;
    line-height: 36px;
  }
}
.accordion-form__method:last-child {
  display: none;
}
