
@arrowL: -279px, -140px, 7px, 12px;
@arrowR: -279px, -124px, 7px, 12px;
@face-1: 0px, -174px, 45px, 45px;
@face-10: -230px, -62px, 58px, 58px;
@face-11: -168px, 0px, 58px, 58px;
@face-12: 0px, -112px, 58px, 58px;
@face-2: -98px, -174px, 45px, 45px;
@face-3: -49px, -174px, 45px, 45px;
@face-4: -147px, -174px, 45px, 45px;
@face-5: -168px, -62px, 45px, 45px;
@face-6: -230px, -124px, 45px, 45px;
@face-7: -62px, -112px, 58px, 58px;
@face-8: -230px, 0px, 58px, 58px;
@face-9: -124px, -112px, 58px, 58px;
@feefo-i: 0px, -78px, 130px, 30px;
@feefo-sm: -85px, -223px, 54px, 13px;
@menuArrow: -279px, -156px, 7px, 11px;
@phone: -134px, -78px, 10px, 10px;
@sitejabber-i: 0px, -44px, 160px, 30px;
@sitejabber-sm: 0px, -223px, 81px, 16px;
@stars: -196px, -198px, 91px, 15px;
@trustpilot-i: 0px, 0px, 164px, 40px;
@trustpilot-sm: -196px, -174px, 83px, 20px;

.sprite-width(@sprite) {
  width: extract(@sprite, 3);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 4);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 1);
  @sprite-offset-y: extract(@sprite, 2);
  background-position: @sprite-offset-x  @sprite-offset-y;
}

.sprite(@sprite) {
  .sprite-position(@sprite);
  background-image: url('img/icons.png');
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

