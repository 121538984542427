.btn-wrap{
  width: 100%;
  .flex(@spacing: center, @direction: column, @ali: center);
}

.help-btn, .helpShadow, .order, .header__btn, .header__login{
  .flex(@spacing: center, @ali: center);
  .font(@family: @open, @size: 14px, @transform: uppercase, @decoration: none, @weight: 700, @col: @white);
  position: relative;
  width: 260px;
  height: 50px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  transition: background .2s, color .6s;
  &.white-btn {
    background: #ffffff;
  }
  &:hover{
    text-decoration: none;
  }
}

.header__btn, .header__login{
  width: 120px;
  height: 44px;
  background: @yellow;
  border: 1px solid transparent;
  color: @white;

  @media (max-width: 768px){
    width: 91px;
    height: 40px;
    font-size: 11px;
  }

}

.chat{
  background: @white;
  color: @violet;
}



.help-btn, .button-cont button {
  background: #663199;
  &:before {
    content: '';
    width: 216px;
    height: 18px;
    box-shadow: 0 13px 20px 0 rgba(37,20,161,0.4);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 767px) {
      width: 90%;
    }
  }
  &:hover{
    background: #8d09f1;
    &:before {
      box-shadow: rgba(141,9,241,0.41);
    }
  }
  &_header {
    &:before {
      display: none;
    }
  }
}

.helpShadow{
  position: relative;
  border-radius: 5px;
  background-color: #663199;

  &:after{
    content:'';
    position: absolute;
    width: 216px;
    height: 6px;
    box-shadow: 0 8px 19px -5px #2414a1;
    bottom: 0;
    border-radius: 100px;
    opacity: 0.4;
  }

  &:hover{
    color: #000;
  }
}

.order{
  color: @violet;
  background: transparent;
  border: 1px solid #663199;

  &:hover{
    background-color: #663199;
    color: @white;
  }
}

