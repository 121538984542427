.hiw{
  padding: 40px 0 0 0;
  @media (max-width: 767px) {
    padding: 40px 0 20px 0;
  }
  .container{
    @media (max-width: 425px){
      padding: 0;
    }
  }

  &__wrapp{
    .flex();
    @media (max-width: 768px){
      .flex(@direction: column);
    }
    .btn-wrapp {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &__left{
    flex: 1;
    .flex(@direction: column, @ali: center, @spacing: flex-start);
    margin-top: 140px;

    .btn-wrapp{
      @media (max-width: 425px){
        display: none;
      }
    }

    @media (max-width: 768px){
      margin-top: 0;
    }
  }

  &__right{
    width: 640px;
    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
    .btn-wrapp{
      display: none;

      @media (max-width: 425px){
        display: block;
        padding: 0 15px;
      }
    }

    .order{
      @media (max-width: 425px){
        width: 100%;
        margin: 0;
      }
    }
  }

  &__txt{
    margin-bottom: 50px;

    @media (max-width: 425px){
      margin-bottom: 0;
    }
  }

  &__title{
    .font(@family: @open, @size: 16px, @weight: 700, @lh: 24px, @col: @hiwTitle, @transform: uppercase);
    margin-bottom: 5px;
    z-index: 1;
  }

  &__descr{
    position: relative;
    .font(@family: @roboto, @size: 16px, @weight: 300, @lh: 24px, @col: @hiwDescr);

    &:before{
      position: absolute;
      font-size: 70px;
      font-weight: bold;
      color: #e9e9e9;;
      top: -35px;
    }

  }

  &__items{
    .flex(@direction: column,  @spacing: center);
    position: relative;
    height: 140px;
    padding-left: 99px;
    @media (max-width: 767px){
      padding-left: 15px;
      margin-bottom: 15px;
      .hiw__title {
        color: @white;
      }
      .hiw__descr{
        color: @hiwHover;

        &:before{
          color: @violet;
        }
      }
    }
    &:hover .hiw__descr{
      color: @hiwHover;

      &:before{
        color: @violet;
      }
    }

    &:hover .hiw__title{
      color: @white;
    }

    &:before{
      content:'';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      opacity: 0;
      transition: opacity .5s ease-in-out;
    }


  }

  &__items-1{
    @media (max-width: 767px) {
      background-image: url(img/hiw-1.jpg);
      opacity: 1;
    }
    &:hover&:before{
      background-image: url(img/hiw-1.jpg);
      opacity: 1;
      @media (max-width: 767px) {
        background-image: none;
        opacity: 0;
      }
    }

    .hiw__descr{
      &:before{
        content:'1';
      }
    }
  }

  &__items-2{
    @media (max-width: 767px) {
      background-image: url(img/hiw-2.jpg);
      opacity: 1;
    }
    &:hover&:before{
      background-image: url(img/hiw-2.jpg);
      opacity: 1;
      @media (max-width: 767px) {
        background-image: none;
        opacity: 0;
      }
    }

    .hiw__descr{
      &:before{
        content:'2';
      }
    }
  }

  &__items-3{
    @media (max-width: 767px) {
      background-image: url(img/hiw-3.jpg);
      opacity: 1;
    }
    &:hover&:before{
      background-image: url(img/hiw-3.jpg);
      opacity: 1;
      @media (max-width: 767px) {
        background-image: none;
        opacity: 0;
      }
    }

    .hiw__descr{
      &:before{
        content:'3';
      }
    }
  }

  &__items-4{
    @media (max-width: 767px) {
      background-image: url(img/hiw-4.jpg);
      opacity: 1;
    }
    &:hover&:before{
      background-image: url(img/hiw-4.jpg);
      opacity: 1;
      @media (max-width: 767px) {
        background-image: none;
        opacity: 0;
      }
    }

    .hiw__descr{
      &:before{
        content:'4';
      }
    }

    @media (max-width: 425px){
      margin-bottom: 20px;
    }
  }

}