.help {
  background: url("img/help-gb.jpg") center 0 no-repeat;
  background-size: cover;
  padding: 50px 0;
}

.help__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1150px;
  @media (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

.help__lt {
  width: 600px;
  @media (max-width: 1200px) {
    text-align: center;
  }
  @media (max-width: 767px) {
    width: 100%;.help__rt
  }
}

.help__rt {
  width: 260px;
  @media (max-width: 767px) {
    width: 100%;
  }
  .white-btn {
    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
  }
}

.help__title {
  color: #ffffff;
  font-size: 44px;
  font-weight: 700;
  line-height: 57px;
  text-transform: uppercase;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    font-size: 36px;
    line-height: 48px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    padding: 0 20px;
  }
}

.help__subtitle {
  font-family: 'Roboto';
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  text-transform: uppercase;
  @media (max-width: 1200px) {
    margin-bottom: 30px;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}