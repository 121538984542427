.top-section{
  padding: 0;
  background-image: url(img/top-section-bg.jpg);
  background-repeat: no-repeat;
  background-position: 0 0;

  &__btn-text {
    margin-top: 9px;
    font-size: 12px;
    color: white;
    font-family: @roboto;

    b {
      font-weight: bold;
    }
  }

  &__wrapp{
    .flex(@ali: center);
    height: 800px;
    @media (max-width: 767px) {
      height: auto;
      padding-top: 110px;
      padding-bottom: 60px;
    }
    .help-btn {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__about {
    .flex(@direction: column, @ali: flex-start, @spacing: center);
    width: 280px;
    height: 100%;
    padding-left: 40px;
    margin-left: -15px;
    background: rgba(0, 0, 0, 0.44);
    z-index: 1;

    @media (max-width: 1024px){
      width: 260px;
    }

    @media (max-width: 1023px){
      display: none;
    }

  }

  &__txt{
    flex: 2;
    text-align: center;
  }

  &__title{
    .font(@family: @open, @size: 20px, @weight: 700, @lh: 52px, @col: @white, @transform: uppercase);
  }

  &__items{
    margin-bottom: 60px;
    p{
      .font(@family: @roboto, @size: 16px, @weight: 400, @lh: 26px, @col: @lightWhite);
    }
  }

  .main-title{
    .font(@family: @open, @size: 56px, @lh: 58px, @transform: uppercase, @col: @white, @weight: 700);

    @media (max-width: 768px){
      .font(@family: @open, @size: 40px, @lh: 48px, @transform: uppercase, @col: @white, @weight: 700);
    }
  }

  .br{
    display: block;

    @media (max-width: 425px){
      display: inline-block;
    }
  }

  .main-subtitle{
    margin: 45px auto 45px auto;
    max-width: 630px;
    .font(@family: @roboto, @size: 20px, @weight: 300, @lh: 36px, @transform: uppercase, @col: @white);

    @media (max-width: 768px){
      .font(@family: @roboto, @size: 16px, @weight: 300, @lh: 24px, @transform: uppercase, @col: @white);
    }
  }

  .help{
    width: 260px;
    height: 56px;

    @media (max-width: 425px){
      width: 100%;
    }
  }

}

.chatbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 210px;
  cursor: pointer;
}

.chatbar__label {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}